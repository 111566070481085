import React from "react"

const ProductDataBlock = ({ title, dataArr }) => {
  if (dataArr === undefined) return null
  return (
    <section className="py-block block-w-container py-10 -mb-px border-b border-gray-100">
      <div className="mx-auto max-w-screen-xlpx-grid">
        {dataArr.length > 0 && (
          <div className="flex flex-row flex-wrap -mx-grid mb-8">
            <div className="w-full lg:w-1/4 px-grid">
              <span className="block py-2 font-bold text-primary">{title}</span>
            </div>
            <div className="w-full lg:w-3/4">
              <table className="w-full table-auto">
                <tbody className="text-sm leading-6 align-baseline">
                  {dataArr.map((item, index) => (
                    <React.Fragment key={`product-data-content-${index}`}>
                      {item.value !== "0,000 X 0,000 X 0,000 " &&
                        item.value !== "0,000 " && (
                          <tr data-prop-key={item.id}>
                            <td className="w-1/2 py-2 lg:w-1/3 px-grid">
                              {item.label}
                            </td>
                            <td className="w-1/2 py-2 lg:w-2/3 px-grid">
                              {item.value}
                            </td>
                          </tr>
                        )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default ProductDataBlock
